.hoox-beekman-difference {
    padding: 48px 0;
    background: #FCEE9C;
    position: relative;
}
.hoox-beekman__title {
    font-size: 28px;
    font-weight: 400;
    line-height: normal;
    color: #414D57;
    text-align: center;
}
.hoox-beekman__btn {
    max-width: 100%;
    width: 100%;
}

.hoox-beekman__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
}

.hoox-beekman-difference__icon {
    display: none;
}

@media(min-width:767px) {

    .hoox-beekman__title {
        font-size: 40px;
        margin-bottom: 0;
    }
    .hoox-beekman-difference{
        padding-top: 70px;
        padding-bottom: 84px;
    }
    .hoox-beekman__btn{
        max-width: 320px;
    }
    .hoox-beekman__wrapper{
        gap: 35px;
    }
    .hoox-beekman-difference__icon {
        display: block;
        position: absolute;
        right: 80px;
        bottom: 30px;
    }
}